import React from 'react';


const Main = () => {


    return (
        <>
            
        </>
    );
}

export default Main;