import React from 'react';

import Painel from '../../components/Painel';
import './style.scss';

const Home = () => {
    
    return (
        <>
            <div className="home">
                <Painel />
            </div>
        </>
    );
}

export default Home;